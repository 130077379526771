import { Button, Statistic, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { InvoiceInfoResponseModel } from "../../../../../models/searchInvoiceModels";
import localStorageManagement from "../../../../../helper/localStorage";
import { InboxOutlined, LoadingOutlined, UserDeleteOutlined } from "@ant-design/icons";
import { useGlobalContext } from "../../../../../hooks/context/globalContext";
import { useSearchInvoiceByAccountContext } from "../../../../../hooks/context/search-invoice/search-by-account/searchInvoiceByAccount.context";
import dayjs from "dayjs";
import SearchByAccountTableRowActions from "./actions/Index";


const SearchInvoiceByAccountTable = () => {
    const globalContext = useGlobalContext();
    const context = useSearchInvoiceByAccountContext();
    const dataColumns: ColumnsType<InvoiceInfoResponseModel> = [
        {
            dataIndex: "inv_invoiceIssuedDate",
            key: "inv_invoiceIssuedDate",
            title: "Ngày hóa đơn",
            align: "center",
            render: (value, record, index) => {
                return dayjs(value).format('DD/MM/YYYY')
            }
        },
        {
            dataIndex: "inv_invoiceNumber",
            key: "inv_invoiceNumber",
            title: "Số hóa đơn",
            align: "right",
            render: (value, record, index) => {
                return Number.parseInt(value);
            }
        },
        {
            dataIndex: "inv_invoiceSeries",
            key: "inv_invoiceSeries",
            title: "Ký hiệu",
            align: "center"
        },
        {
            dataIndex: "inv_TotalAmount",
            key: "inv_TotalAmount",
            title: "Tổng tiền",
            align: "right",
            render: (value, record, index) => {
                return new Intl.NumberFormat('vi-VI', { style: 'currency', currency: 'VND' }).format(value);
            },
        },
        {
            dataIndex: "mau_hd",
            key: "mau_hd",
            title: "Mẫu số",
            align: "center"
        },
        {
            dataIndex: "button",
            key: "button",
            title: "",
            align: "center",
            render: (value, record, index) => <SearchByAccountTableRowActions key={index} record={record} />
        }
    ]
    const showLoginForm = () => {
        globalContext.setLoginModalOpen(true);
    }

    const getEmptyTextOverlay = () => {

        if (localStorageManagement.isValidToken()) {
            return (
                <>
                    <InboxOutlined className="table-local-icon" style={{ fontSize: '4em' }} /><br />
                    <span>Không có dữ liệu, vui lòng thử tìm kiếm lại</span>
                </>
            )
        }
        return (
            <>
                <UserDeleteOutlined className="table-local-icon" size={1000} /><br />
                <span>Vui lòng <Button onClick={showLoginForm}>Đăng nhập</Button> để tra cứu theo tài khoản</span>
            </>
        )
    }
    return <Table className="search-by-account-table"
        bordered
        columns={dataColumns}
        locale={{
            emptyText() {
                return getEmptyTextOverlay();
            },
        }}
        rowKey={(record) => record.inv_InvoiceAuth_id}
        scroll={{ y: "35vh" }}
        loading={{
            spinning: globalContext.isLoading,
            indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
        }}
        dataSource={context.data.items}
        pagination={false}
    >
    </Table>
}
export default SearchInvoiceByAccountTable;