import './Index.css'
import { useNavigate } from 'react-router-dom'
const HeaderLogo = () => {
    const navigate = useNavigate();
    const backHome = () => {
        navigate('/')
    }
    return <a className='logo' onClick={backHome}>
        <img src={`${process.env.PUBLIC_URL}/assets/img/logo/minvoice-horizontal.png`}></img>
    </a>
}

export default HeaderLogo;