import SearchSingleModal from "../../../../../../components/tra-cuu-hoa-don/body/search-single/modal/Index";
import SearchSingleInvoiceToolbar from "./tool-bars/Index";


const SearchSingleInvoiceModal: React.FC<{
    pdfBlobUrl: string | null
}> = ({ pdfBlobUrl }) => {


    const toolbar = <SearchSingleInvoiceToolbar blobUrl={pdfBlobUrl}/>
    return <SearchSingleModal
        blobUrl={pdfBlobUrl}
        toolbar={toolbar}
    />
}
export default SearchSingleInvoiceModal;