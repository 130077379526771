

import { Modal, Spin } from "antd";
import SearchSinglePdfView from "./PdfView";
import { useEffect, useState } from "react";
import './Index.css'
import { useGlobalContext } from "../../../../../hooks/context/globalContext";
import { LoadingOutlined } from "@ant-design/icons";

const SearchSingleModal: React.FC<{
    blobUrl: string | null,
    toolbar: React.ReactNode
}> = ({ blobUrl, toolbar }) => {

    const [open, setOpen] = useState(false);
    useEffect(() => {
        setOpen(blobUrl !== null);
    }, [blobUrl])

    const globalContext = useGlobalContext();




    return <Modal className="search-single-result-modal"
        open={open}
        onCancel={() => setOpen(false)}
        destroyOnClose={true}
        footer={<></>}
        closable={!globalContext.isLoading}
    >
        <Spin spinning={globalContext.isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} >
            {toolbar}

            <SearchSinglePdfView blobUrl={blobUrl} />



        </Spin>
    </Modal>
}
export default SearchSingleModal;