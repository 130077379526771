import { Menu } from "antd";


const TraCuuGiamThueButton = () => {
    return <Menu style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        border: 'none',
    }}>
        <Menu.Item key="tra-cuu-mat-hang-giam-thue-nghi-dinh-44-2023-nd-cp" danger>
            <a href={process.env.REACT_APP_TRA_CUU_GIAM_THUE_URL} target="_blank">Tra cứu mặt hàng giảm thuế theo Nghị định 44/2023/NĐ-CP</a>
        </Menu.Item>
    </Menu>
}
export default TraCuuGiamThueButton;