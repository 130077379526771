import { Col, Row } from "antd"
import { useLocation } from 'react-router-dom'
import { traCuuHoaDonSubRoute } from "../../../routes/Index";

const SearchInvoiceBodyContent: React.FC<{
    contentColSpan?: number,
    content: React.ReactNode
}> = ({ contentColSpan = 8, content }) => {
    const location = useLocation()
    const getTitle = () => {
        const path = location.pathname;
        const route = traCuuHoaDonSubRoute.find((r) => r.key === path)
        return route?.page_title ?? <h1>{route?.title}</h1>
    }
    return (<Row>
        <Col xs={0} sm={0} md={(24 - contentColSpan) / 2} />
        <Col xs={24} sm={24} md={contentColSpan} >
            {getTitle()}
            {content}
        </Col>
        <Col xs={0} sm={0} md={(24 - contentColSpan) / 2} />
    </Row>)
}
export default SearchInvoiceBodyContent