import { Content } from "antd/es/layout/layout"
import SearchForm from "./SearchForm"
import MinvoiceProduct from "../../../components/minvoice-products/Index"
import MinvoiceParterSlide from "../../../components/slide/Index"
const TracuuHoaDonBody = () => {
    return <Content>
        <SearchForm />
        <MinvoiceProduct />
        <MinvoiceParterSlide />
    </Content>
}
export default TracuuHoaDonBody