import { Menu } from "antd"
import HeaderData from '../../../../helper/constants/SearchInvoice/header'
import './Index.css'
const RightHeader = () => {

    return <Menu
        className="search-invoice-header-left-menu"
        mode="horizontal"
        selectable={false}
        items={HeaderData.CenterMenu}
    />
}
export default RightHeader