import { AxiosResponse } from "axios";
import useAxios from "../hooks/useAxios"


const useStaticFileApis = () => {
    const axios = useAxios();
    const getLogoImages: () => Promise<AxiosResponse<string[]>> = () => {
        return axios.get('StaticFile/GetLogoImages');
    }


    return {
        getLogoImages
    }
}
export default useStaticFileApis