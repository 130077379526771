import { Modal } from "antd";
import LoginForm from "../form/Index";
import { useGlobalContext } from "../../../hooks/context/globalContext";


const LoginModal = () => {

    const globalContext = useGlobalContext();

    return <Modal open={globalContext.isLoginModalOpen}
        title="Đăng nhập"
        footer={<></>}
        onCancel={() => { globalContext.setLoginModalOpen(false) }}
    >
        <LoginForm />
    </Modal>

}
export default LoginModal;