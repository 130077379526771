import localStorageManagement from "../helper/localStorage";
import { useGlobalContext } from "../hooks/context/globalContext";
import useAxios from "../hooks/useAxios";
import { AccountModel, LoginModel } from "../models/searchInvoiceModels";

const useAccountApi = () => {
    const axios = useAxios();
    const globalContext = useGlobalContext();
    const login = async (loginModel: LoginModel) => {
        await axios.post('Account/Login', loginModel).then(response => {
            if (!response) return;
            let account: AccountModel = {
                ...response.data?.data
            }
            localStorageManagement.setUser(account);
            globalContext.setUser(account);
        })

    }
    const logout = () => {
        localStorageManagement.removeUser();
        globalContext.setUser(null);
    }
    return {
        login, logout
    }
}
export default useAccountApi;