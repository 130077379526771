import { Content } from "antd/es/layout/layout";


const SearchSingle: React.FC<{
    form: React.ReactNode,
    modal: React.ReactNode
}> = ({ form, modal }) => {


    return <Content>
        {form}
        {modal}
    </Content>
}
export default SearchSingle;