import { Menu, Space } from "antd";
import TraCuuGiamThueButton from "../TraCuuGiamThueButton";
import SearchInvoiceMenu from "../../Menu";


const CenterHeader = () => {


    return <TraCuuGiamThueButton />


}
export default CenterHeader;