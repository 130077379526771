import { Content } from "antd/es/layout/layout";
import { useEffect, useRef, useState } from "react";
import { register } from 'swiper/element/bundle';
import useStaticFileApis from "../../apis/static-file.api";
import MinvoiceParterSlideItem from "./Item";
register();


const MinvoiceParterSlide = () => {
    const [images, setImages] = useState([]);

    const swiperElRef = useRef(null);
    const staticFileApis = useStaticFileApis();

    useEffect(() => {
        staticFileApis.getLogoImages().then((response) => {
            if (!response) return;
            setImages(response.data)
        })
        return () => {
            setImages([])
        }
    }, [])
    return (
        <Content>
            <swiper-container
                ref={swiperElRef}
                slides-per-view="6"
                autoplay-delay="2500"
                loop="true"
                css-mode="true"
            >
                {
                    images.map((image, index) => <MinvoiceParterSlideItem imageSrc={image} key={index} />)
                }
            </swiper-container>
        </Content>
    )
}
export default MinvoiceParterSlide;