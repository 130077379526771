import { KeyOutlined, LogoutOutlined } from "@ant-design/icons";
import { Button, List } from "antd";
import useAccountApi from "../../../apis/account.api";

const AccountInformationPanel = () => {

    const accountApi = useAccountApi();
    return (
        <List>
            {/* <List.Item>
                <Button icon={<KeyOutlined />} disabled={true}>Đổi mật khẩu</Button>
            </List.Item> */}
            <List.Item>
                <Button
                    icon={<LogoutOutlined />}
                    danger
                    onClick={accountApi.logout}
                >
                    Đăng xuất
                </Button>
            </List.Item>
        </List>
    )
}
export default AccountInformationPanel