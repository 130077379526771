import { Row } from "antd";
import SearchInvoiceByAccountTable from "./table/Index";
import SearchInvoiceByAccountSearchBar from "./search-bar/Index";
import SearchInvoiceBodyContent from "../../../../components/tra-cuu-hoa-don/body/Content";
import { useEffect, useState } from "react";
import localStorageManagement from "../../../../helper/localStorage";
import { SearchByAccountQueryModel, SearchByAccountResponseModel } from "../../../../models/searchInvoiceModels";
import { SearchInvoiceByAccountContext } from "../../../../hooks/context/search-invoice/search-by-account/searchInvoiceByAccount.context";
import useSearchInvoiceApis from "../../../../apis/search-invoice.api";


const SearchInvoiceByAccount = () => {
    const [data, setData] = useState<SearchByAccountResponseModel>({ totalItems: 0, items: [] });
    const [searchInfo, setSearchInfo] = useState<SearchByAccountQueryModel>({ from: undefined, to: undefined, skip: 0, take: 50 })
    const [reload, setReload] = useState<boolean>(false);

    const searchApis = useSearchInvoiceApis();
    useEffect(() => {
        let user = localStorageManagement.getUser();
        let isValidToken = localStorageManagement.isValidToken();
        if (!user || !isValidToken) localStorageManagement.removeUser();
    }, [])

    useEffect(() => {
        setReload(false);
        if (!searchInfo.from || !searchInfo.to || reload !== true) return;
        searchApis
            .getInvoiceByAccount(searchInfo)
            .then(response => {
                setData(response);
            })
        return () => {
            setData({ totalItems: 0, items: [] })
        }

    }, [reload])



    const content = (<Row style={{ textAlign: "center" }}>
        <SearchInvoiceByAccountSearchBar />
        <SearchInvoiceByAccountTable />
    </Row>)
    const contextValue = {
        data: data,
        reload: reload,
        searchInfo: searchInfo,
        setSearchInfo: setSearchInfo,
        setReload: setReload
    }
    return <SearchInvoiceByAccountContext.Provider value={contextValue}>

        <SearchInvoiceBodyContent
            content={content}
            contentColSpan={24}
        />
    </SearchInvoiceByAccountContext.Provider>


}
export default SearchInvoiceByAccount;