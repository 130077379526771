import { Button, Form, Input } from "antd";
import { RuleObject } from "antd/es/form";
import { useForm } from "antd/es/form/Form";
import { SearchSingleFormModel } from "../../../../../models/searchInvoiceModels";
import { useGlobalContext } from "../../../../../hooks/context/globalContext";
import { useEffect } from "react";
import useQuery from "../../../../../hooks/useQuery";
import { useSearchSingleInvoiceContext } from "../../../../../hooks/context/search-invoice/single/invoice/searchSingleInvoice.context";


const validateTaxCode = (ruleObject: RuleObject, value: string) => {
    value = String(value).trim();
    let patt = /^[0-9]{10}$/;
    if (value.length > 10) {
        patt = /^[0-9]{10}[-][0-9]{3}$/;
    }
    var number =
        Number(value.charAt(0)) * 31 +
        Number(value.charAt(1)) * 29 +
        Number(value.charAt(2)) * 23 +
        Number(value.charAt(3)) * 19 +
        Number(value.charAt(4)) * 17 +
        Number(value.charAt(5)) * 13 +
        Number(value.charAt(6)) * 7 +
        Number(value.charAt(7)) * 5 +
        Number(value.charAt(8)) * 3;

    let isValidTaxCode = patt.test(value) && (10 - (number % 11) == Number(value.charAt(9)));
    return isValidTaxCode ? Promise.resolve() : Promise.reject(new Error('Mã số thuế không hợp lệ'))
}
const taxCodeRules = [
    { required: true, message: "Vui lòng nhập mã số thuế" },
    {
        validator(_: RuleObject, value: string) {
            return validateTaxCode(_, value);
        },
    }
]
const securityCodeRules = [
    { required: true, message: "Vui lòng nhập số bảo mật" }
]


const SearchSingleForm: React.FC<{
    onSubmit: (formValue: SearchSingleFormModel) => void
}> = ({ onSubmit }) => {

    const [form] = useForm();
    const onFormSubmit = () => {
        form.validateFields().then((formValues) => {
            onSubmit(formValues);
        }).catch(() => { })

    }
    const globalContext = useGlobalContext();
    const query = useQuery();
    useEffect(() => {
        const mst = query.get("masothue");
        const sobaomat = query.get("sobaomat");
        if (!mst || !sobaomat) return;
        form.setFieldsValue({ taxCode: mst, securityCode: sobaomat })
        onFormSubmit();

    }, [])


    return <Form onSubmitCapture={onFormSubmit} form={form}>
        <Form.Item
            label="Mã số thuế"
            name="taxCode"
            rules={taxCodeRules}
            className="form-label-message"
        //initialValue={formValuesInit.taxCode}
        >
            <Input />
        </Form.Item>
        <Form.Item
            label="Số bảo mật"
            name="securityCode"
            rules={securityCodeRules}
            className="form-label-message"
        //initialValue={formValuesInit.securityCode}
        >
            <Input />
        </Form.Item>
        <Form.Item>
            <Button loading={globalContext.isLoading} type="primary" htmlType="submit">
                Tra cứu
            </Button>
        </Form.Item>

    </Form>
}
export default SearchSingleForm;