import dayjs from "dayjs"
import { Dispatch, SetStateAction, createContext, useContext } from "react"
import { SearchByAccountQueryModel, SearchByAccountResponseModel } from "../../../../models/searchInvoiceModels"

export type SearchInvoiceByAccountContextModel = {
    searchInfo: SearchByAccountQueryModel,
    setSearchInfo: Dispatch<SetStateAction<SearchByAccountQueryModel>>,
    reload: boolean,
    setReload: Dispatch<SetStateAction<boolean>>,
    data: SearchByAccountResponseModel
}
export const SearchInvoiceByAccountContext = createContext<SearchInvoiceByAccountContextModel>({
    searchInfo: {
        from: dayjs().startOf('day'),
        to: dayjs().endOf('day'),
        skip: 0,
        take: 50
    },
    setSearchInfo: () => { },
    reload: false,
    setReload: () => { },
    data: {
        totalItems: 0,
        items: []
    }
})
export const useSearchInvoiceByAccountContext = () => useContext(SearchInvoiceByAccountContext)