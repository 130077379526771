import { Avatar, Popover } from "antd";
import AccountInformation from "./Infomation";
import { UserOutlined } from "@ant-design/icons";
import { useGlobalContext } from "../../../hooks/context/globalContext";


const AccountInfomationPopover = () => {
    const globalContext = useGlobalContext();


    if (!globalContext.user) {
        return <></>
    }
    return <Popover
        placement="bottomLeft"
        title={globalContext.user?.username}
        content={<AccountInformation />}>
        <Avatar shape="square" icon={<UserOutlined />} />
    </Popover>
}
export default AccountInfomationPopover;