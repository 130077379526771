import axios from "axios";
import { App } from 'antd';
import AxiosErrorModel, { Data } from "../models/axiosErrorModel";
import { useGlobalContext } from "./context/globalContext";
import localStorageManagement from "../helper/localStorage";

const useAxios = () => {
    const app = App.useApp();
    const globalContext = useGlobalContext();
    let port: any;
    let baseUrl: any;
    if (document.location.port === "3000") {
        baseUrl = process.env.REACT_APP_PUBLIC_URL;
    }
    else {
        if (document.location.port !== "80" && document.location.port !== "445") {
            port = ":" + document.location.port.toString();
        }
        baseUrl = document.location.protocol + "//" + document.location.hostname + port + "/api";
    }
    const readError = (error: AxiosErrorModel | null) => {
        if (error === null) return;
        if (error.response?.status === 401) {
            localStorageManagement.removeUser();
            app.notification.error({ message: "Có lỗi xảy ra", description: `Phiên đăng nhập đã hết hạn` });
        }
        if (error.request?.responseType === "arraybuffer") {
            let errorObject = JSON.parse(new TextDecoder().decode(error?.response?.data as (BufferSource | undefined)));
            app.notification.error({ message: "Có lỗi xảy ra", description: `${errorObject.message}` });
            return;
        }
        app.notification.error({ message: "Có lỗi xảy ra", description: `${(error?.response?.data as (Data | undefined))?.message}` });
    }
    const _axios = axios.create({
        baseURL: baseUrl,
        xsrfHeaderName: 'RequestVerificationToken',
    });
    _axios.interceptors.request.use((config) => {
        globalContext.setLoading(true);
        return config;
    })
    _axios.interceptors.response.use(
        (response) => {
            globalContext.setLoading(false);
            return response;
        }, (error) => {
            globalContext.setLoading(false);
            readError(error)
        });
    return _axios;
}
export default useAxios;