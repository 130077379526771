import { Divider } from "antd"
import { Agent } from "../../../../models/customModels"

const MinvoiceAddressItem: React.FC<{
    address: Agent
}> = ({ address }) => {
    return (<>
        <Divider className="agent-info-child"></Divider>
        <h4 className="agent-info-child" >{address.name}</h4>
        <p className="agent-info-child">{address.copyRight}</p>
        <p className="agent-info-child">Địa chỉ: {address.address}</p>
        <p className="agent-info-child">SĐT: {address.phone}</p>
        <a href={`mailto: ${address.email}`} className="agent-info-child">Email: {address.email}</a>
    </>)
}
export default MinvoiceAddressItem