import { useEffect } from 'react';
import { hubConnection } from 'signalr-no-jquery';
const url = "http://localhost:19898/signalr";
const options = {
    useDefaultPath: false
}
const connection = hubConnection(url, options);
const hub = connection.createHubProxy('invoiceHub', options);

connection.start()
.done(function () { console.log('Now connected, connection ID=' + connection.id); })
.fail(function () { console.log('Could not connect'); });
connection.disconnected(function () {
    console.log("hub disconnected");
});

const useMinvoicePlugin = () => {
    useEffect(() => {
       
        hub.on('resCommand', function (message) {
            var res = JSON.parse(message);
            if (message.code !== "00") {
                console.log(res);
            }
        });
        // return () => {
        //     connection.stop()
        // }
    }, [])

    return { hub, connection }
}

export default useMinvoicePlugin;