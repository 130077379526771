import { ConfigProvider } from 'antd';
import './App.css';
import { App as AntdApp } from 'antd';
import { useEffect, useState } from 'react';
import { GlobalContext } from '../hooks/context/globalContext';
import viVN from 'antd/lib/locale/vi_VN';
import AppRoutes from '../routes/Index';
import LoginModal from '../components/account/modal/Index';
import { AccountModel } from '../models/searchInvoiceModels';
import localStorageManagement from '../helper/localStorage';
function App() {



  const [isLoading, setLoading] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [user, setUser] = useState<AccountModel | null>(localStorageManagement.isValidToken() ? localStorageManagement.getUser() : null);

  useEffect(() => {
    if (!localStorageManagement.isValidToken()) return;
    setUser(localStorageManagement.getUser());
  }, [])


  return (
    <ConfigProvider theme={{
      token: {
        colorPrimary: '#363793',
        colorError: '#F7120C',
      }
    }}
      locale={viVN}>
      <AntdApp className='app'>
        <GlobalContext.Provider value={{
          isLoading, setLoading,
          isLoginModalOpen: isLoginOpen,
          setLoginModalOpen: setIsLoginOpen,
          user: user,
          setUser: setUser
        }}>
          <AppRoutes />
          <LoginModal />
        </GlobalContext.Provider>
      </AntdApp>
    </ConfigProvider>

  );
}

export default App;
