import { FileZipOutlined } from "@ant-design/icons"
import { Button, Form, UploadProps, Upload, notification } from "antd"
import Dragger from "antd/es/upload/Dragger"
import { useSearchInvoiceByFileContext } from "../../../../hooks/context/search-invoice/search-by-file/searchInvoiceByFile.context"
import { useForm } from "antd/es/form/Form"
import { useGlobalContext } from "../../../../hooks/context/globalContext"
const acceptType = "application/x-zip-compressed"
const TWO_MB = 2097152
const SearchInvoiceByFileForm = () => {

    const [form] = useForm();
    const context = useSearchInvoiceByFileContext();
    const globalContext = useGlobalContext();
    const props: UploadProps = {
        multiple: false,
        onChange(info) {
            console.log(info);
            if (info.fileList.length === 0) {
                context.setFile(null);
                return;
            }
            context.setFile(info.file)
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
        beforeUpload(file, FileList) {
            const isValidType = file.type === acceptType;
            if (!isValidType) {
                notification.error({ message: "Tệp không đúng định dạng!", description: "Tệp không đúng định dạng. Vui lòng chỉ chọn tệp .zip" })
                return Upload.LIST_IGNORE;
            }
            const isValidSize = file.size < TWO_MB
            if (!isValidSize) {
                notification.error({ message: "Vượt quá dung lượng cho phép", description: "Vui lòng chọn tệp có dung lượng nhỏ hơn 2MB" })
                return Upload.LIST_IGNORE;
            }
            return false;
        },
    };
    const onSubmit = () => {
        context.setReload(true);
    }

    return <Form form={form}>
        <Form.Item rules={[{ required: true }]}>
            <Dragger
                {...props}
                name='file'
                multiple={false}
                maxCount={1}
                accept={acceptType}>
                <p className="ant-upload-drag-icon">
                    <FileZipOutlined />
                </p>
                <p className="ant-upload-text">Nhấp chuột hoặc kéo thả tập tin hóa đơn vào đây để tải lên</p>
                <p className="ant-upload-hint">
                    Chỉ chọn một tập tin có phần mở rộng .zip với dung lượng tối đa 2MB
                </p>
            </Dragger>
        </Form.Item>

        <Form.Item>
            <Button type='primary' loading={globalContext.isLoading} onClick={onSubmit}>Tra cứu</Button>
        </Form.Item>
    </Form>
}
export default SearchInvoiceByFileForm