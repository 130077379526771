import { Divider, Space } from "antd"
import FooterData from "../../../../helper/constants/SearchInvoice/footer";
import MinvoiceSocialItem from "./Item";
import './Index.css'
const MinvoiceSocials = () => {


    return <Divider orientation="center" className="minvoice-socials">
        <Space split={<Divider type="vertical" />}>
            {
                FooterData.SocialLinks.map((socalLink, index) => (
                    <MinvoiceSocialItem key={index} socalLink={socalLink} />
                ))
            }
        </Space>
    </Divider>
}
export default MinvoiceSocials