import SearchSingleForm from "../../../../../../components/tra-cuu-hoa-don/body/search-single/form/Index"
import { useSearchSingleProofOfIncomeTaxContext } from "../../../../../../hooks/context/search-invoice/single/proof/searchSingleProofOfIncomeTax.context"
import { SearchSingleFormModel } from "../../../../../../models/searchInvoiceModels";


const SearchSingleProofOfTaxIncomeForm = () => {
    const context = useSearchSingleProofOfIncomeTaxContext();
    const onSubmit = (formValue: SearchSingleFormModel) => {
        context.setSearchInfo(formValue)
        context.setReload(true);
    }
    return <SearchSingleForm onSubmit={onSubmit} />
}
export default SearchSingleProofOfTaxIncomeForm