import { Layout } from "antd"
import TraCuuHoaDonHeader from "../../pages/tra-cuu-hoa-don/header/Index"
import TraCuuHoaDonFooter from "../../pages/tra-cuu-hoa-don/footer/Index"
import SearchInvoiceMenu from "../../pages/tra-cuu-hoa-don/Menu"

const TraCuuHoaDonLayout: React.FC<{
    body: React.ReactNode
}> = ({ body }) => {

    return <Layout>
        <TraCuuHoaDonHeader />
        <SearchInvoiceMenu />
        {body}
        <TraCuuHoaDonFooter />
    </Layout>
}
export default TraCuuHoaDonLayout