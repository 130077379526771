import { createContext, useContext } from "react"
import { AccountModel } from "../../models/searchInvoiceModels"
export type GlobalContent = {
    isLoading: boolean
    setLoading: (value: boolean) => void,
    isLoginModalOpen: boolean,
    setLoginModalOpen: (value: boolean) => void,
    user: AccountModel | null,
    setUser: (value: AccountModel | null) => void
}
export const GlobalContext = createContext<GlobalContent>({
    isLoading: false, // set a default value
    setLoading: () => { },
    isLoginModalOpen: false,
    setLoginModalOpen: () => { },
    user: null,
    setUser: () => { }
})
export const useGlobalContext = () => useContext(GlobalContext)