
import { Badge, Button } from "antd";
import { MinvoiceProduct } from "../../../models/customModels";
import { FireFilled } from "@ant-design/icons";

const MInvoiceProducts: MinvoiceProduct[] = [
    {
        href: "",
        imageUrl: "./assets/img/logo/bhxh.png",
        name: "Phần mềm bảo hiểm xã hội",
        content: [
            "Giải pháp kê khai và nộp trực tiếp hồ sơ đến Bảo hiểm xã hội Việt Nam",
            "Hỗ trợ kê khai và tư vấn nghiệp vụ giúp doanh nghiệp thuận tiện khi tham gia BHXH",
        ],
        imageProps: {
            className: 'minvoice-product-card-cover-contain'
        },
        button: []
    },
    {
        href: "",
        imageUrl: "./assets/img/logo/mcontract.png",
        imageProps: {
            className: 'minvoice-product-card-cover-contain'
        },
        name: "Phần mềm hợp đồng điện tử",
        content: [
            " M-Contract hỗ trợ số hóa quy trình nội bộ, ký kết hợp đồng lao động, hợp đồng thương mai và thanh toán điện tử cho doanh nghiệp.",
        ],
        button: []
    },
    {
        href: "",
        imageUrl: "./assets/img/logo/smi.png",
        imageProps: {
            className: 'minvoice-product-card-cover-contain'
        },
        name: "Phần mềm quản lý hóa đơn",
        content: [
            "M-Invoice SMI là giải pháp quản lý toàn bộ hóa đơn mua vào, bán ra của doanh nghiệp",
            "Hệ thống hỗ trợ kê khai thuế GTGT, tự động phát hiện hóa đơn không hợp lệ",
        ],
        button: [
            (
                <Badge className="minvoice-product-card-button" count={<FireFilled />}>
                    <Button href='https://minvoice.vn/bao-gia/#quanlySMI' target="_blank">Báo giá</Button>

                </Badge>
            ),
            (
                <Badge className="minvoice-product-card-button" count={<FireFilled />}>
                    <Button href='https://youtu.be/7yLu11cpgEs' target="_blank">Demo</Button>
                </Badge>
            )
        ]
    },
    {
        href: "",
        imageUrl: "./assets/img/logo/ca.png",
        imageProps: {

            className: 'minvoice-product-card-cover-contain'
        },
        name: "Giải pháp ký số",
        content: [
            "M-Invoice cung cấp Chữ ký số USB Token, HSM, remote signning và các giải pháp ký số không sử dụng USB Token đáp ứng nhu cầu ký số mọi lúc mọi nơi mọi thiết bị.",
        ],
        button: []
    },
];
export default { MInvoiceProducts }