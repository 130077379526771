import { Button } from "antd"
import { SocialLink } from "../../../../models/customModels"


const MinvoiceSocialItem: React.FC<{
    socalLink: SocialLink
}> = ({ socalLink }) => {

    return <Button
        icon={socalLink.icon}
        href={socalLink.href}
        target="_blank"> {socalLink.name}
    </Button>
}
export default MinvoiceSocialItem