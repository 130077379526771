
import { SearchByAccountQueryModel, SearchByAccountResponseModel, SearchSingleInvoiceModel, TaxCodeInfoModel } from "../models/searchInvoiceModels";
import useMinvoicePlugin from "../hooks/useMinvoicePluginHub";
import useAxios from "../hooks/useAxios";
import { App } from "antd";
import { useCallback } from "react";
import localStorageManagement from "../helper/localStorage";
const searchSingleInvoiceUrl = "Search/SearchInvoice"
const downloadXmlInvoiceUrl = "Search/DownloadXml"
const searchByFileUrl = "Search/SearchInvoiceByFile"
const searchProofUrl = "Search/SearchVoucher"
const searchInvoiceInfoUrl = "Search/SearchInvoiceInfo"
const getSellerSignatureUrl = "Search/GetSellerSignature"
const searchTaxCodeUrl = "Search/SearchTaxCodeInfo"
const searchInvoiceByAccount = "Search/SearchInvoiceByAccount"

const useSearchInvoiceApis = () => {
    const axios = useAxios();
    const MinvoicePlugin = useMinvoicePlugin();
    const app = App.useApp();
    const getPdfInvoice: (masothue: string, sobaomat: string, inchuyendoi: boolean) => Promise<string | null>
        = useCallback(async (masothue, sobaomat, inchuyendoi) => {
            const response = await axios.get(searchSingleInvoiceUrl, {
                params: {
                    masothue,
                    sobaomat,
                    type: "PDF",
                    inchuyendoi
                },
                responseType: 'arraybuffer'
            });
            if (!response) return null;
            const file = new Blob([response.data], {
                type: "application/pdf",
            });
            const fileURL = URL.createObjectURL(file);
            return fileURL;
        }, [axios]);
    const getXml: (masothue: string, sobaomat: string) => Promise<string | null>
        = useCallback(async (masothue, sobaomat) => {
            const response = await axios.get(downloadXmlInvoiceUrl, {
                params: {
                    masothue,
                    sobaomat,
                },
                responseType: "arraybuffer"
            });
            if (!response) return null;
            const file = new Blob([response.data], {
                type: "application/zip",
            });
            const fileURL = URL.createObjectURL(file);
            return fileURL;
        }, [axios])
    const getInvoiceByFile: (fileToSearch: FormData) => Promise<string | null>
        = useCallback(async (fileToSearch) => {
            const response = await axios.post(searchByFileUrl, fileToSearch, {
                responseType: "arraybuffer"
            });
            if (!response) return null;
            const file = new Blob([response?.data], {
                type: "application/pdf",
            });
            const fileURL = URL.createObjectURL(file);
            return fileURL;
        }, [axios])
    const getProofOfPersonalIncomeTaxReduce: (masothue: string, sobaomat: string) => Promise<string | null>
        = useCallback(async (masothue, sobaomat) => {
            const response = await axios.get(searchProofUrl, {
                params: {
                    masothue,
                    sobaomat,
                    type: "PDF"
                },
                responseType: 'arraybuffer'
            });
            if (!response) return null;
            const file = new Blob([response.data], {
                type: "application/pdf",
            });
            const fileURL = URL.createObjectURL(file);
            return fileURL;
        }, [axios])
    const getInvoiceInfo: (masothue: string, sobaomat: string) => Promise<SearchSingleInvoiceModel | null>
        = useCallback(async (masothue, sobaomat) => {
            const response = await axios.get(searchInvoiceInfoUrl, {
                params: {
                    masothue,
                    sobaomat
                }
            });
            if (!response) return null;
            return response.data?.data;
        }, [axios])
    const getTaxCodeInfo: (taxCode: string) => Promise<TaxCodeInfoModel | null>
        = useCallback(async (taxCode) => {
            const response = await axios.get(searchTaxCodeUrl, {
                params: {
                    masothue: taxCode
                }
            });
            if (!response) return null;
            return response.data?.data;
        }, [axios])
    const getInvoiceByAccount: (params: SearchByAccountQueryModel) => Promise<SearchByAccountResponseModel>
        = async (params) => {
            let user = localStorageManagement.getUser();
            return await axios.get(searchInvoiceByAccount, {
                params: {
                    tu_ngay: params.from?.toJSON(),
                    den_ngay: params.to?.toJSON(),
                    skip: params.skip,
                    take: params.take
                },
                headers: {
                    Authorization: `Bearer ${user?.token}`
                }
            }).then((response) => {
                if (!response) return [];
                return response.data?.data
            })
        }
    const getSellerSignature: (masothue: string, sobaomat: string) => Promise<string | null>
        = useCallback(async (masothue, sobaomat) => {
            return await axios.get(getSellerSignatureUrl, { params: { masothue: masothue, sobaomat: sobaomat } }).then(response => {
                return response.data
            })
        }, [axios])
    const readBuyerToken: (ecd?: string | null) => void
        = (ecd) => {

            if (!ecd) {
                app.message.error("Không tìm thấy thông tin chữ ký số");
                return;
            }
            var jsonRead = { xml: ecd, id: "seller" };
            console.log(ecd);

            MinvoicePlugin.connection.start();
            if (MinvoicePlugin.connection.state !== 1) {
                app.message.error(`Bạn chưa bật hoặc cài công cụ đọc chữ ký số`);
                return;
            }
            MinvoicePlugin.hub
                .invoke("execCommand", "ShowCert2", JSON.stringify(jsonRead))
                .done(function (res: any) {
                    console.log(res);
                })
                .fail(function (error: any) {
                    console.log(error);
                });

        }

    return {
        getInvoiceByFile, getPdfInvoice,
        getProofOfPersonalIncomeTaxReduce, getXml, getTaxCodeInfo,
        getInvoiceInfo, getInvoiceByAccount, readBuyerToken, getSellerSignature
    }
}
export default useSearchInvoiceApis;