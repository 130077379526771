

const MinvoiceCopyRight = () => {


    return <p style={{ textAlign: "center" }}>
        Copyrights © {new Date().getFullYear()} Công ty TNHH Hóa đơn điện tử <strong><span>
            M-Invoice
        </span></strong>. All Rights Reserved.
    </p>
}
export default MinvoiceCopyRight