
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import TraCuuHoaDon from '../pages/tra-cuu-hoa-don/Index';
import SearchSingleInvoice from '../pages/tra-cuu-hoa-don/body/search-single/invoice/Index';
import SearchSingleProofOfIncomeTax from '../pages/tra-cuu-hoa-don/body/search-single/proof-of-income-tax/Index';
import { FileProtectOutlined, FileSearchOutlined, FileTextOutlined, IdcardOutlined, UploadOutlined } from '@ant-design/icons';
import SearchInvoiceByFile from '../pages/tra-cuu-hoa-don/body/search-by-file/Index';
import SearchInvoiceByAccount from '../pages/tra-cuu-hoa-don/body/search-by-account/Index';
import AccountInfomationPopover from '../components/account/information/Index';
import { Divider, Space } from 'antd';

export interface RouteInfo {
    key: string,
    element?: React.ReactNode,
    title: string,
    page_title?: string | React.ReactNode,
    label?: string | React.ReactNode,
    icon?: React.ReactNode,
    children?: RouteInfo[]
}
export const traCuuHoaDonSubRoute: RouteInfo[] = [
    {
        key: '/single/invoice',
        title: "Tra cứu hóa đơn",
        label: "Tra cứu hóa đơn",
        icon: <FileTextOutlined />,
        element: <SearchSingleInvoice />
    },
    {
        key: '/single/proof-of-personal-income-tax-deduce',
        title: "Tra cứu CT khấu trừ thuế TNCN",
        page_title: <h1>Tra cứu chứng từ khấu từ thuế thu nhập cá nhân</h1>,
        label: "Tra cứu CT khấu trừ thuế TNCN",
        icon: <FileSearchOutlined />,
        element: <SearchSingleProofOfIncomeTax />
    },
    {
        key: "/file",
        title: "Tra cứu theo file",
        label: "Tra cứu theo file",
        icon: <UploadOutlined />,
        element: <SearchInvoiceByFile />
    },
    {
        key: "/account",
        title: "Tra cứu theo tài khoản",
        page_title: <Space split={<Divider type='vertical' />}>
            <h1>Tra cứu theo tài khoản</h1>
            <AccountInfomationPopover />
        </Space>,
        label: "Tra cứu theo tài khoản",
        icon: <IdcardOutlined />,
        element: <SearchInvoiceByAccount />
    },
    {
        label: (<a href="https://kiemtrahoadon.vn/" target="_blank">Kiểm tra hóa đơn hợp lệ</a>),
        title: "Kiểm tra hóa đơn hợp lệ",
        key: "/kiem-tra-hoa-don",
        icon: <FileProtectOutlined />
    }
]
export const routes: RouteInfo[] = [
    {
        key: "/",
        element: <TraCuuHoaDon />,
        title: "Tra cứu hóa đơn",
        children: traCuuHoaDonSubRoute
    }
]
const AppRoutes = () => {

    const getRoute = (routes?: RouteInfo[]) => {
        if (!routes) return <></>
        return routes.filter(route => route.element).map((route, index) => {
            return <Route
                key={index}
                path={route.key}
                element={route.element}>
                {getRoute(route.children)}
            </Route>
        })
    }
    return <BrowserRouter>
        <Routes>
            <Route path='/'>
                <Route index element={<Navigate to={traCuuHoaDonSubRoute[0].key} replace />} />
                {getRoute(routes)}
            </Route>
            
        </Routes>
    </BrowserRouter>
}
export default AppRoutes;