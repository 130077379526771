import SearchSingleForm from "../../../../../../components/tra-cuu-hoa-don/body/search-single/form/Index"
import { useSearchSingleInvoiceContext } from "../../../../../../hooks/context/search-invoice/single/invoice/searchSingleInvoice.context"
import { SearchSingleFormModel } from "../../../../../../models/searchInvoiceModels";


const SearchSingleInvoiceForm = () => {
    const context = useSearchSingleInvoiceContext();
    const onSubmit = (formValue: SearchSingleFormModel) => {
        context.setSearchInfo(formValue);
        context.setReload(true);
    }
    return <SearchSingleForm onSubmit={onSubmit}/>
}
export default SearchSingleInvoiceForm