import SearchSingleModal from "../../../../../../components/tra-cuu-hoa-don/body/search-single/modal/Index";
import SearchSingleProofOfIncomeTaxToolbar from "./tool-bars/Index";


const SearchSingleProofOfIncomeTaxModal: React.FC<{
    pdfBlobUrl: string | null
}> = ({ pdfBlobUrl }) => {


    const toolbar = <SearchSingleProofOfIncomeTaxToolbar blobUrl={pdfBlobUrl}  />
    return <SearchSingleModal
        blobUrl={pdfBlobUrl}
        toolbar={toolbar}
    />
}
export default SearchSingleProofOfIncomeTaxModal;