import { Dispatch, SetStateAction, createContext, useContext } from "react"
import { SearchSingleFormModel } from "../../../../../models/searchInvoiceModels"
export type SearchSingleProofOfIncomeTaxContextModel = {
    searchInfo: SearchSingleFormModel | undefined,
    setSearchInfo: Dispatch<SetStateAction<SearchSingleFormModel | undefined>>,
    reload: boolean,
    setReload: Dispatch<SetStateAction<boolean>>
}
export const SearchSingleProofOfIncomeTaxContext = createContext<SearchSingleProofOfIncomeTaxContextModel>({
    searchInfo: undefined,
    setSearchInfo: () => { },
    reload: false,
    setReload: () => { }
})
export const useSearchSingleProofOfIncomeTaxContext = () => useContext(SearchSingleProofOfIncomeTaxContext)