

const SearchSinglePdfView: React.FC<{
    blobUrl: string | null
}> = ({ blobUrl }) => {
    return <iframe
        style={{ height:"100%", width: "100%", aspectRatio: "1 / 1.414" }}
        src={`${blobUrl ?? undefined}#toolbar=0&view=Fit`}
    />

}
export default SearchSinglePdfView;