import { Avatar, Image } from "antd"

const MinvoiceParterSlideItem = ({ imageSrc }) => {


    return <swiper-slide>
        <Image
            src={imageSrc}
            style={{
                width: '100%',
                height: '100%',
                objectFit: "cover"
            }}

        />
    </swiper-slide>
}
export default MinvoiceParterSlideItem