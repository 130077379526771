import { Card } from "antd"
import { MinvoiceProduct } from "../../../models/customModels"
import MinvoiceProductItemContent from "./ItemContent"
import './Index.css'

const MinvoiceProductItem: React.FC<{
    item: MinvoiceProduct,
    index: number
}> = ({ item, index }) => {

    const cover = <img
        style={item.imageProps?.styleProps}
        className={`minvoice-product-card-cover ${item.imageProps?.className}`}
        alt="example"
        src={`${process.env.PUBLIC_URL}/${item.imageUrl}`} />
    const actions = item.button.map((minvoiceProductButton, indexButton) => (
        minvoiceProductButton
    ))

    const content = item.content.map((minvoiceProductContent, indexContent) => (
        <MinvoiceProductItemContent content={minvoiceProductContent} key={indexContent} />

    ))
    return <Card
        bordered={true}
        hoverable
        cover={cover}
        actions={actions}
        style={{ height: '100%', padding: '2%' }}
        className={`minvoice-product-card `}

    >
        {
            content
        }
    </Card>
}
export default MinvoiceProductItem