import { Dispatch, SetStateAction, createContext, useContext } from "react"

export type SearchInvoiceByFileContextModel = {
    file: any | null,
    setFile: Dispatch<SetStateAction<any | null>>,
    reload: boolean,
    setReload: Dispatch<SetStateAction<boolean>>
}
export const SearchInvoiceByFileContext = createContext<SearchInvoiceByFileContextModel>({
    file: null,
    setFile: () => { },
    reload: false,
    setReload: () => { }
})
export const useSearchInvoiceByFileContext = () => useContext(SearchInvoiceByFileContext)