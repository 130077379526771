import Meta from "antd/es/card/Meta";


const MinvoiceProductItemContent: React.FC<{
    content: string
}> = ({ content }) => {

    return <Meta className="minvoice-product-card-description"
        description={content} />
}
export default MinvoiceProductItemContent;