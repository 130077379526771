import { Col, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import bodyData from "../../helper/constants/SearchInvoice/body";
import MinvoiceProductItem from "./item/Index";
import "./Index.css";
const MinvoiceProduct: React.FC = () => {

    return (
        <Content className="minvoice-product-show">
            <Row style={{ paddingRight: 200, paddingLeft: 200 }}>
                {
                    bodyData.MInvoiceProducts.map((minvoiceProduct, index) => (
                        <Col
                            key={index}
                            xs={24} sm={8} md={24 / bodyData.MInvoiceProducts.length}
                            style={{ padding: '1%' }}
                        >

                            <MinvoiceProductItem item={minvoiceProduct} index={index} />
                        </Col>
                    ))
                }
            </Row>
        </Content>
    )
}
export default MinvoiceProduct;