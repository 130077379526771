import { Pagination } from "antd";
import { useSearchInvoiceByAccountContext } from "../../../../../hooks/context/search-invoice/search-by-account/searchInvoiceByAccount.context";
import { useGlobalContext } from "../../../../../hooks/context/globalContext";


const SearchByAccountPagination = () => {

    const context = useSearchInvoiceByAccountContext();
    const globalContext = useGlobalContext();
    const onChange = (page: number, pageSize: number) => {
        context.setSearchInfo({ ...context.searchInfo, skip: (page - 1) * pageSize, take: pageSize })
        context.setReload(true);

    }
    return <Pagination
        disabled={globalContext.user === null}
        total={context.data.totalItems}
        pageSize={context.searchInfo.take}
        current={(context.searchInfo.skip / context.searchInfo.take) + 1}
        showSizeChanger={true}
        onChange={onChange}
    />



}
export default SearchByAccountPagination;