import { Dispatch, SetStateAction, createContext, useContext } from "react"
import { SearchSingleFormModel } from "../../../../../models/searchInvoiceModels"
export type SearchSingleInvoiceContextModel = {
    searchInfo: SearchSingleFormModel | undefined,
    setSearchInfo: (value: SearchSingleFormModel) => void,
    reload: boolean,
    setReload: Dispatch<SetStateAction<boolean>>
}
export const SearchSingleInvoiceContext = createContext<SearchSingleInvoiceContextModel>({
    searchInfo: undefined,
    setSearchInfo: () => { },
    reload: false,
    setReload: () => { }
})
export const useSearchSingleInvoiceContext = () => useContext(SearchSingleInvoiceContext)