import { useEffect, useState } from "react";
import SearchInvoiceBodyContent from "../../../../../components/tra-cuu-hoa-don/body/Content";
import SearchSingle from "../../../../../components/tra-cuu-hoa-don/body/search-single/Index";
import SearchSingleInvoiceForm from "./form/Index";
import { SearchSingleFormModel } from "../../../../../models/searchInvoiceModels";
import { SearchSingleInvoiceContext } from "../../../../../hooks/context/search-invoice/single/invoice/searchSingleInvoice.context";
import useSearchInvoiceApis from "../../../../../apis/search-invoice.api";
import SearchSingleInvoiceModal from "./modal/Index";


const SearchSingleInvoice = () => {
    const [reload, setReload] = useState<boolean>(false);
    const [searchInfo, setSearchInfo] = useState<SearchSingleFormModel>()
    const [pdfBlobUrl, setPdfBlobUrl] = useState<string | null>(null);
    const searchInvoiceApis = useSearchInvoiceApis();

    useEffect(() => {
        setReload(false);
        if (!searchInfo?.securityCode || !searchInfo?.taxCode || reload !== true) {
            return;
        }
        searchInvoiceApis
            .getPdfInvoice(searchInfo.taxCode, searchInfo.securityCode, false)
            .then((response) => {
                setPdfBlobUrl(response?.slice(0) ?? null)
            })
        return () => {
            setReload(false);
        }
    }, [JSON.stringify(searchInfo), reload])

    const content = <SearchSingle
        form={<SearchSingleInvoiceForm />}
        modal={<SearchSingleInvoiceModal pdfBlobUrl={pdfBlobUrl} />}
    />
    const contextProviderValue = {
        searchInfo: searchInfo,
        setSearchInfo: setSearchInfo,
        reload: reload,
        setReload: setReload
    }
    return (
        <SearchSingleInvoiceContext.Provider value={contextProviderValue}>
            <SearchInvoiceBodyContent
                content={content}
            />
        </SearchSingleInvoiceContext.Provider>
    )
}
export default SearchSingleInvoice;