import { Col, Row } from "antd"
import { Header } from "antd/es/layout/layout"
import LeftHeader from "./left/Index"
import CenterHeader from "./center/Index"
import RightHeader from "./right/Index"
import './Index.css'
const TraCuuHoaDonHeader = () => {

    return <Header className="nav">
        <Row style={{ height: "100%" }} >
            <Col xs={0} md={8} style={{ height: "100%", textAlign: 'start' }} >
                <LeftHeader />
            </Col>
            <Col xs={24} md={8} style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <CenterHeader />
            </Col>
            <Col xs={0} md={8} style={{
                display: "flex",
                alignItems: "center",
                width: "100%"
            }}>
                <RightHeader />
            </Col>
        </Row>
    </Header >
}
export default TraCuuHoaDonHeader