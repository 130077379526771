import { useEffect, useState } from "react";
import SearchInvoiceBodyContent from "../../../../components/tra-cuu-hoa-don/body/Content";
import SearchInvoiceByFileForm from "./Form";
import { SearchInvoiceByFileContext } from "../../../../hooks/context/search-invoice/search-by-file/searchInvoiceByFile.context";
import { notification } from "antd";
import useSearchInvoiceApis from "../../../../apis/search-invoice.api";


const SearchInvoiceByFile = () => {

    const [file, setFile] = useState<any | null>(null);
    const [reload, setReload] = useState<boolean>(false);
    const searchApis = useSearchInvoiceApis();

    useEffect(() => {
        setReload(false);
        if (reload !== true) {
            return;
        }
        if (!file) {
            notification.error({ message: "Vui lòng chọn tệp", description: "Vui lòng chọn một tệp cần tra cứu" })
            return;
        }
        const formData = new FormData();
        formData.append("file", file);
        searchApis
            .getInvoiceByFile(formData)
            .then((response) => {
                if(!response) return;
                window.open(response ?? undefined);
            })
    }, [JSON.stringify(file), reload])

    return <SearchInvoiceByFileContext.Provider value={{ file: file, setFile: setFile, reload: reload, setReload: setReload }} >
        <SearchInvoiceBodyContent
            content={<SearchInvoiceByFileForm />}
        />
    </SearchInvoiceByFileContext.Provider>

}
export default SearchInvoiceByFile;