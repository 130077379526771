import { Button } from "antd"
import { InvoiceInfoResponseModel } from "../../../../../../models/searchInvoiceModels"
import { SearchSingleInvoiceContext, SearchSingleInvoiceContextModel } from "../../../../../../hooks/context/search-invoice/single/invoice/searchSingleInvoice.context"
import localStorageManagement from "../../../../../../helper/localStorage"
import { useState } from "react"
import SearchSingleInvoiceModal from "../../../search-single/invoice/modal/Index"
import useSearchInvoiceApis from "../../../../../../apis/search-invoice.api"
import { FilePdfOutlined } from "@ant-design/icons"


const SearchByAccountViewPdf: React.FC<{
    record: InvoiceInfoResponseModel
}> = ({ record }) => {
    const user = localStorageManagement.getUser()
    const [blobUrl, setBlobUrl] = useState<string | null>(null);

    const searchApi = useSearchInvoiceApis();
    const onClick = () => {
        if (!user?.mst || !record.sobaomat) return;
        searchApi
            .getPdfInvoice(user.mst, record.sobaomat, false)
            .then(response => {
                setBlobUrl(response);
            })
    }
    const contextValue: SearchSingleInvoiceContextModel = {
        searchInfo: {
            taxCode: user?.mst ?? "",
            securityCode: record.sobaomat ?? ""
        },
        setSearchInfo: () => { },
        reload: false,
        setReload: () => { }
    }
    return <SearchSingleInvoiceContext.Provider value={contextValue} >
        <Button onClick={onClick} icon={<FilePdfOutlined />}>
            Xem in PDF
        </Button>
        <SearchSingleInvoiceModal pdfBlobUrl={blobUrl} />
    </SearchSingleInvoiceContext.Provider>
}
export default SearchByAccountViewPdf