import { Col, Row, Space } from "antd";
import SearchBarDatePicker from "./DatePicker";
import SearchByAccountPagination from "./Pagination";

const SearchInvoiceByAccountSearchBar = () => {

    return <Row style={{ width: "100%" }}>
        <Col xs={0} sm={0} md={8}></Col>
        <Col xs={16} sm={16} md={8}>
            <Space>
                <SearchBarDatePicker />
            </Space>

        </Col>
        <Col span={8} style={{ textAlign: "end" }}>
            <SearchByAccountPagination />

        </Col>
    </Row>
}
export default SearchInvoiceByAccountSearchBar;