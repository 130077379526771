import { Space } from "antd"
import SearchByAccountViewPdf from "./ViewPdf"
import { InvoiceInfoResponseModel } from "../../../../../../models/searchInvoiceModels"
import SearchByAccountDownloadXmlButton from "./DownloadXmlButton"


const SearchByAccountTableRowActions: React.FC<{
    record: InvoiceInfoResponseModel
}> = ({ record }) => {





    return <Space.Compact>
        <SearchByAccountViewPdf record={record} />
        <SearchByAccountDownloadXmlButton record={record} />
    </Space.Compact>
}
export default SearchByAccountTableRowActions