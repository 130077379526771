import { Footer } from "antd/es/layout/layout";
import MinvoiceAddresses from "./minvoice-addresses/Index";
import MinvoiceSocials from "./minvoice-socials/Index";
import MinvoiceCopyRight from "./minvoice-copyright/Index";
import { theme } from 'antd'

const TraCuuHoaDonFooter = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return <Footer style={{ background: colorBgContainer }}>
        <MinvoiceAddresses />
        <MinvoiceSocials />
        <MinvoiceCopyRight />
    </Footer>
}
export default TraCuuHoaDonFooter;