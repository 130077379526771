import { Button } from "antd";
import { ItemType, MenuItemType } from "antd/es/menu/hooks/useItems";

const CenterMenu: ItemType<MenuItemType>[] = [
    {
        label: "Sản phẩm",
        title: "Sản phẩm",
        key: "san-pham",
        children: [
            {
                label: (<a href="https://minvoice.vn/" target="_blank">Bảo hiểm xã hội điện tử</a>),
                key: "bao-hiem-xa-hoi-minvoice",
                title: "Bảo hiểm xã hội điện tử"
            },
            {
                label: (<a href="https://minvoice.vn/" target="_blank">Quản lý rủi ro hóa đơn mSMI</a>),
                key: "smi-minvoice",
                title: "Quản lý rủi ro hóa đơn mSMI"
            },
            {
                label: (<a href="https://minvoice.vn/" target="_blank">Hợp đồng điện tử</a>),
                key: "me-contract-hop-dong-dien-tu-minvoice",
                title: "Hợp đồng điện tử"
            }
        ]
    },
    {
        label: (<a href="https://minvoice.vn/dang-ky-su-dung/" target="_blank">Mua hàng</a>),
        title: "Mua hàng",
        key: "mua-hang-minvoice",
        danger:true
    },
    {
        label: (<Button style={{ color: "white" }} type="primary" href="https://minvoice.vn/dang-ky-dai-ly-hoa-don-dien-tu/" target="_blank">Đăng ký đại lý</Button>),
        title: "",
        key: "dang-ky-dai-ly-minvoice"
    }
]
export default { CenterMenu }