import { SearchOutlined } from "@ant-design/icons"
import { Button, DatePicker, Space, notification } from "antd"
import { useSearchInvoiceByAccountContext } from "../../../../../hooks/context/search-invoice/search-by-account/searchInvoiceByAccount.context"
import dayjs from "dayjs";
import { useGlobalContext } from "../../../../../hooks/context/globalContext";

const SearchBarDatePicker = () => {
    const context = useSearchInvoiceByAccountContext();
    const globalContext = useGlobalContext();


    const search = () => {
        if (!context.searchInfo.from || !context.searchInfo.to) {
            notification.error({ message: "Vui lòng chọn ngày tra cứu", description: "Vui lòng chọn khoảng ngày cần tra cứu hóa đơn" });
            return;
        }
        context.setReload(true);
    }
    return (<Space.Compact>
        <DatePicker.RangePicker
            disabledDate={(date) => date > dayjs()}
            value={[context.searchInfo.from ?? null, context.searchInfo.to ?? null]}
            onChange={(values) => {
                context.setSearchInfo({
                    ...context.searchInfo,
                    from: values?.at(0) ?? undefined,
                    to: values?.at(1) ?? undefined
                })
            }}
        />
        <Button
            disabled={globalContext.user === null}
            onClick={search}
            loading={globalContext.isLoading}
            icon={<SearchOutlined />} />
    </Space.Compact>)
}
export default SearchBarDatePicker