import { Menu } from "antd";
import { MenuItemType } from "antd/es/menu/hooks/useItems";


const SearchSingleToolbar: React.FC<{
    items: MenuItemType[]
}> = ({items}) => {

    return <Menu
        items={items}
        mode="horizontal"

    />

}
export default SearchSingleToolbar;