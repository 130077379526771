import { FacebookFilled, PhoneFilled, PlaySquareFilled, YoutubeFilled } from "@ant-design/icons";
import { Agent, SocialLink } from "../../../models/customModels";

const Agents: Agent[] = [
    {
        name: "CÔNG TY TNHH HÓA ĐƠN ĐIỆN TỬ M-INVOICE",
        copyRight: "Số ĐKKD 0106026495 do Sở KHĐT Tp. Hà Nội cấp ngày 30/12/2012",
        address: "Tòa nhà M-invoice, Số 12-14 Ngõ 269/1 Đường Giáp Bát, P. Giáp Bát, Q. Hoàng Mai, Hà Nội",
        phone: "0901 80 16 18",
        email: "info@minvoice.vn"
    },
    {
        name: "CHI NHÁNH HỒ CHÍ MINH",
        copyRight: "",
        address: "Số 2 Đường Nguyễn Thế Lộc, Phường 12, Quận Tân Bình",
        phone: "0909 500 126",
        email: "info@minvoice.vn"
    },
    {
        name: "CHI NHÁNH TÂY ĐÔ",
        copyRight: "",
        address: "Số 03 Quảng Trọng Hoàng, P. Hưng Lợi, Q. Ninh Kiều,TP. Cần Thơ",
        phone: "0918 640 366",
        email: "manhkhan.ct@gmail.com"
    },
    {
        name: "CHI NHÁNH HÀ NỘI",
        copyRight: "",
        address: "Số nhà 27 liền kề 14 KĐT Văn Khê, Quận Hà Đông, Hà Nội",
        phone: "0899 90 16 18",
        email: "info@minvoice.vn"
    },
    {
        name: "CHI NHÁNH ĐÀ NẴNG",
        copyRight: "",
        address: "Số 100, Lê Tấn Trung, P. Thọ Quang,Quận Sơn Trà",
        phone: "0915 777 591",
        email: "info@minvoice.vn"
    },
    {
        name: "CHI NHÁNH HẢI DƯƠNG",
        copyRight: "",
        address: "Khu 1, Phường Thạch Khôi, Tp.Hải Dương",
        phone: "02203 833 666",
        email: "info@minvoice.vn"
    },
    {
        name: "CHI NHÁNH THANH HÓA",
        copyRight: "",
        address: "Số 68 Trần Bình Trọng, P.Đông Sơn,Thành phố Thanh Hóa",
        phone: "0901 80 16 18",
        email: "info@minvoice.vn"
    },
    {
        name: "CHI NHÁNH ĐỒNG NAI",
        copyRight: "",
        address: "R318, Đường D8, KP 7, P. Thống Nhất,Thành phố Biên Hoà",
        phone: "0919 29 22 33",
        email: "info@minvoice.vn"
    },
    {
        name: "CHI NHÁNH MIỀN TRUNG",
        copyRight: "",
        address: "230/8 Nguyễn Công Trứ, P.An Hải Đông, Q.Sơn Trà, TP.Đà Nẵng",
        phone: "0982.839.777 - 0905.332.011",
        email: "mientrung@minvoice.vn"
    }
]
const SocialLinks: SocialLink[] = [
    {
        name: "Youtube",
        href: "https://www.youtube.com/channel/UCJd2b57TomvwJOMyXhYVQpQ",
        icon: <YoutubeFilled />,
       
    },
    {
        name: "Facebook",
        href: "https://www.facebook.com/hoadondientu.m.invoice",
        icon: <FacebookFilled />,
        
    },
    {
        name: "0901 80 16 18",
        href: "tel:0901801816",
        icon: <PhoneFilled />,
      
    },
    {
        name: "Demo",
        href: "https://youtu.be/umLV8QLS9A4",
        icon: <PlaySquareFilled />,
       
    }
]
export default { Agents, SocialLinks }