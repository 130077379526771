import { AccountModel } from "../models/searchInvoiceModels";
const userKey = "user";
const getUser: () => AccountModel | null = () => {
    let rawUser = localStorage.getItem(userKey);
    if (!rawUser) return null;
    let user = JSON.parse(localStorage.getItem(userKey) as string);
    return user as AccountModel | null;
}
const setUser: (account: AccountModel) => void = (account) => {
    localStorage.setItem(userKey, JSON.stringify(account));
}
const removeUser: () => void = () => {
    localStorage.removeItem(userKey);
}
const isValidToken: () => boolean = () => {
    let user = getUser();
    if (!user) return false;
    let expireTime = new Date(user?.expires ?? new Date());
    return expireTime > new Date();
}
const localStorageManagement = {
    getUser,
    setUser,
    removeUser,
    isValidToken
}
export default localStorageManagement