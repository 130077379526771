import { Col, Row } from "antd"
import FooterData from "../../../../helper/constants/SearchInvoice/footer";
import MinvoiceAddressItem from "./Item";

const MinvoiceAddresses = () => {
    return <Row gutter={16} style={{ height: "100%" }} >
        {
            FooterData.Agents.map((agent, index) => (

                <Col xs={24} md={8} className="agent-info" key={index} span={8}>
                    <MinvoiceAddressItem address={agent} />
                </Col>
            ))
        }

    </Row>
}
export default MinvoiceAddresses