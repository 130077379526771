import { MenuItemType } from "antd/es/menu/hooks/useItems";
import SearchSingleToolbar from "../../../../../../../components/tra-cuu-hoa-don/body/search-single/modal/tool-bars/Index";
import { SearchSingleFormModel } from "../../../../../../../models/searchInvoiceModels";
import useSearchInvoiceApis from "../../../../../../../apis/search-invoice.api";
import { useGlobalContext } from "../../../../../../../hooks/context/globalContext";
import { useSearchSingleInvoiceContext } from "../../../../../../../hooks/context/search-invoice/single/invoice/searchSingleInvoice.context";


const SearchSingleInvoiceToolbar: React.FC<{
    blobUrl: string | null
}> = ({ blobUrl }) => {

    const globalContext = useGlobalContext();
    const searchSingleInvoiceContext = useSearchSingleInvoiceContext();
    const searchApis = useSearchInvoiceApis();


    const menuItems: MenuItemType[] = [
        {
            key: "download-pdf",
            title: "Tải PDF",
            label: "Tải PDF",
            onClick: () => {
                if (!blobUrl) return;
                const a = document.createElement('a');
                a.href = blobUrl;
                a.download = searchSingleInvoiceContext.searchInfo ? `${searchSingleInvoiceContext.searchInfo.taxCode}_${searchSingleInvoiceContext.searchInfo.securityCode}.pdf` : `${crypto.randomUUID()}.pdf`;
                a.click();
            },
            disabled: globalContext.isLoading
        },
        {
            key: "download-xml",
            title: "Tải XML",
            label: "Tải XML",

            onClick: () => {
                if (!searchSingleInvoiceContext.searchInfo) return;
                searchApis.getXml(searchSingleInvoiceContext.searchInfo.taxCode, searchSingleInvoiceContext.searchInfo.securityCode).then(response => {
                    if (!response) return;
                    const a = document.createElement('a');
                    a.href = response;
                    a.download = searchSingleInvoiceContext.searchInfo ? `${searchSingleInvoiceContext.searchInfo.taxCode}_${searchSingleInvoiceContext.searchInfo.securityCode}.zip` : `${crypto.randomUUID()}.zip`;
                    a.click();
                })
            },
            disabled: globalContext.isLoading
        },
        {
            key: "print-convert",
            title: "In chuyển đổi",
            label: "In chuyển đổi",
            onClick: () => {
                if (!searchSingleInvoiceContext.searchInfo) return;
                searchApis
                    .getPdfInvoice(
                        searchSingleInvoiceContext.searchInfo.taxCode,
                        searchSingleInvoiceContext.searchInfo.securityCode,
                        true
                    )
                    .then(response => {
                        if (!response) return;
                        window.open(response);
                    })
            },
            disabled: globalContext.isLoading
        },
        {
            key: "lookup-tax-invoice",
            title: "Tra cứu hóa đơn của CQT",
            label: "Tra cứu hóa đơn của CQT",
            onClick: () => {
                window.open('http://tracuuhoadon.gdt.gov.vn/tc1hd.html', '_blank')
            }
        },
        {
            key: "read-signature",
            title: "Đọc CKS người bán",
            label: "Đọc CKS người bán",
            onClick: () => {
                if (!searchSingleInvoiceContext.searchInfo) return;
                searchApis
                    .getSellerSignature(searchSingleInvoiceContext.searchInfo.taxCode, searchSingleInvoiceContext.searchInfo.securityCode)
                    .then((response) => {
                        searchApis.readBuyerToken(response);
                    })
            }
        },
        {
            key: "download-signature-reader",
            title: "Tải công cụ đọc CKS",
            label: "Tải công cụ đọc CKS ",
            onClick: () => window.open('https://installupdate.minvoice.vn/MinvoicePlugin/setup.exe', '_blank')
        }

    ]
    return <SearchSingleToolbar
        items={menuItems}
    />

}
export default SearchSingleInvoiceToolbar;