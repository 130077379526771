import { MenuItemType } from "antd/es/menu/hooks/useItems";
import SearchSingleToolbar from "../../../../../../../components/tra-cuu-hoa-don/body/search-single/modal/tool-bars/Index";
import useSearchInvoiceApis from "../../../../../../../apis/search-invoice.api";
import { useGlobalContext } from "../../../../../../../hooks/context/globalContext";
import { useSearchSingleProofOfIncomeTaxContext } from "../../../../../../../hooks/context/search-invoice/single/proof/searchSingleProofOfIncomeTax.context";


const SearchSingleProofOfIncomeTaxToolbar: React.FC<{
    blobUrl: string | null
}> = ({ blobUrl }) => {

    const globalContext = useGlobalContext();
    const searchSingleProofOfIncomeTaxContext = useSearchSingleProofOfIncomeTaxContext();
    const searchApis = useSearchInvoiceApis();


    const menuItems: MenuItemType[] = [
        {
            key: "download-pdf",
            title: "Tải PDF",
            label: "Tải PDF",
            onClick: () => {
                if (!blobUrl) return;
                const a = document.createElement('a');
                a.href = blobUrl;
                a.download = searchSingleProofOfIncomeTaxContext.searchInfo ? `${searchSingleProofOfIncomeTaxContext.searchInfo.taxCode}_${searchSingleProofOfIncomeTaxContext.searchInfo.securityCode}.pdf` : `${crypto.randomUUID()}.pdf`;
                a.click();
            },
            disabled: globalContext.isLoading
        },
        {
            key: "download-xml",
            title: "Tải XML",
            label: "Tải XML",

            onClick: () => {
                if (!searchSingleProofOfIncomeTaxContext.searchInfo) return;
                searchApis.getXml(searchSingleProofOfIncomeTaxContext.searchInfo.taxCode, searchSingleProofOfIncomeTaxContext.searchInfo.securityCode).then(response => {
                    if (!response) return;
                    const a = document.createElement('a');
                    a.href = response;
                    a.download = searchSingleProofOfIncomeTaxContext.searchInfo ? `${searchSingleProofOfIncomeTaxContext.searchInfo.taxCode}_${searchSingleProofOfIncomeTaxContext.searchInfo.securityCode}.zip` : `${crypto.randomUUID()}.zip`;
                    a.click();
                })
            },
            disabled: globalContext.isLoading
        },
        {
            key: "lookup-tax-invoice",
            title: "Tra cứu hóa đơn của CQT",
            label: "Tra cứu hóa đơn của CQT",
            onClick: () => {
                window.open('http://tracuuhoadon.gdt.gov.vn/tc1hd.html', '_blank')
            }
        },
        {
            key: "read-signature",
            title: "Đọc CKS  tổ chức trả thu nhập",
            label: "Đọc CKS tổ chức trả thu nhập",
            onClick: () => {
                if (!searchSingleProofOfIncomeTaxContext.searchInfo) return;
                searchApis
                    .getSellerSignature(searchSingleProofOfIncomeTaxContext.searchInfo.taxCode, searchSingleProofOfIncomeTaxContext.searchInfo.securityCode)
                    .then((response) => {
                        searchApis.readBuyerToken(response);
                    })
            }
        },
        {
            key: "download-signature-reader",
            title: "Tải công cụ đọc CKS",
            label: "Tải công cụ đọc CKS ",
            onClick: () => window.open('https://installupdate.minvoice.vn/MinvoicePlugin/setup.exe', '_blank')
        }

    ]
    return <SearchSingleToolbar
        items={menuItems}
    />

}
export default SearchSingleProofOfIncomeTaxToolbar;