import { Button, Form, Input } from "antd";
import { useGlobalContext } from "../../../hooks/context/globalContext";
import useAccountApi from "../../../apis/account.api";
import { LoginModel } from "../../../models/searchInvoiceModels";

const LoginForm = () => {
    const globalContext = useGlobalContext();
    const accountApi = useAccountApi();


    const login = (values: LoginModel) => {
        accountApi.login(values).then(response => globalContext.setLoginModalOpen(false));
    }
    return <Form
        name="searchByAccountForm"
        initialValues={{ remember: true }
        }
        autoComplete="off"
        className=""
        onFinish={login}
        labelCol={{ span: 6, style: { textAlign: "start" } }}
        wrapperCol={{ span: 18 }}
    >
        <Form.Item
            label="Mã số thuế"
            name="mst"
            rules={[
                { required: true, message: "Vui lòng nhập mã số thuế" },
                { pattern: RegExp("([a-zA-Z0-9]{10}[-][a-zA-Z0-9]{3})|([a-zA-Z0-9]{10})"), message: "Mã số thuế không hợp lệ" }
            ]}
            className="form-label-message"
        >
            <Input />
        </Form.Item>
        <Form.Item
            label="Tài khoản"
            name="username"
            rules={[
                { required: true, message: "Vui lòng nhập tài khoản tra cứu" },
            ]}
            className="form-label-message"
        >
            <Input />
        </Form.Item>
        <Form.Item
            label="Mật khẩu"
            name="password"
            rules={[{ required: true, message: "Vui lòng nhập mật khẩu" }]}
            className="form-label-message"
        >
            <Input type="password" />
        </Form.Item>
        <Button loading={globalContext.isLoading} type="primary" htmlType="submit">
            Đăng nhập
        </Button>
    </Form >
}
export default LoginForm;