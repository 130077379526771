import { Menu } from "antd";
import useQuery from "../../hooks/useQuery";
import { useNavigate, useLocation } from 'react-router-dom'
import { useState, useEffect, useRef } from 'react'
import { traCuuHoaDonSubRoute } from "../../routes/Index";

const SearchInvoiceMenu = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const query = useQuery();
    const [path, setPath] = useState(traCuuHoaDonSubRoute.some(r => r.key === location.pathname && r.element) ? location.pathname : traCuuHoaDonSubRoute[0].key);
    const firstRender = useRef(true);
    useEffect(() => {

        if (!traCuuHoaDonSubRoute.some(r => r.key === path && r.element)) {
            setPath(location.pathname)
            return
        }
        const taxCode = query.get('masothue');
        const securityCode = query.get('sobaomat');
        if (!taxCode || !securityCode || !firstRender.current) {
            navigate(path);
            return;
        }
        navigate(`${path}?masothue=${taxCode}&sobaomat=${securityCode}`)
        firstRender.current = false;


    }, [path])


    return <Menu
        mode="horizontal"
        className="search-invoice-menu"
        items={traCuuHoaDonSubRoute}
        selectedKeys={[path]}
        onClick={(menuInfo) => {
            const uri = menuInfo.keyPath.filter((value) => value !== "rc-menu-more").reverse().join('/')
            setPath(`${uri}`);
        }}
    />
}
export default SearchInvoiceMenu;