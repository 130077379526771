import { useEffect, useState } from "react";
import SearchInvoiceBodyContent from "../../../../../components/tra-cuu-hoa-don/body/Content";
import SearchSingle from "../../../../../components/tra-cuu-hoa-don/body/search-single/Index";
import { SearchSingleProofOfIncomeTaxContext } from "../../../../../hooks/context/search-invoice/single/proof/searchSingleProofOfIncomeTax.context";
import SearchSingleProofOfTaxIncomeForm from "./form/Index";
import { SearchSingleFormModel } from "../../../../../models/searchInvoiceModels";
import useSearchInvoiceApis from "../../../../../apis/search-invoice.api";
import SearchSingleProofOfIncomeTaxModal from "./modal/Index";


const SearchSingleProofOfIncomeTax = () => {
    const [searchInfo, setSearchInfo] = useState<SearchSingleFormModel>();
    const [reload, setReload] = useState<boolean>(false);
    const [pdfBlobUrl, setPdfBlobUrl] = useState<string | null>(null);
    const searchInvoiceApis = useSearchInvoiceApis();

    useEffect(() => {
        setReload(false);
        if (!searchInfo?.securityCode || !searchInfo?.taxCode || reload !== true) {
            return;
        }
        searchInvoiceApis
            .getProofOfPersonalIncomeTaxReduce(searchInfo.taxCode, searchInfo.securityCode)
            .then((response) => {
                setPdfBlobUrl(response?.slice(0) ?? null)
            })
        return () => {
            setReload(false);
        }
    }, [JSON.stringify(searchInfo), reload])
    const content = <SearchSingle
        form={<SearchSingleProofOfTaxIncomeForm />}
        modal={<SearchSingleProofOfIncomeTaxModal pdfBlobUrl={pdfBlobUrl} />}
    />
    return <SearchSingleProofOfIncomeTaxContext.Provider value={{ searchInfo: searchInfo, setSearchInfo: setSearchInfo, reload: reload, setReload: setReload }} >
        <SearchInvoiceBodyContent
            content={content}
        />
    </SearchSingleProofOfIncomeTaxContext.Provider>



}
export default SearchSingleProofOfIncomeTax;