import { theme } from "antd"
import { Outlet } from 'react-router-dom'
import { Content } from "antd/es/layout/layout";
const SearchForm = () => {

    const {
        token: { colorBgContainer },
    } = theme.useToken();
    return (
        <Content style={{ background: colorBgContainer, textAlign: "center" }}>
            <Outlet />
        </Content>
    )

}
export default SearchForm