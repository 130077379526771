import { FileProtectOutlined } from "@ant-design/icons"
import { Button } from "antd"
import { InvoiceInfoResponseModel } from "../../../../../../models/searchInvoiceModels"
import useSearchInvoiceApis from "../../../../../../apis/search-invoice.api"
import localStorageManagement from "../../../../../../helper/localStorage"

const SearchByAccountDownloadXmlButton: React.FC<{
    record: InvoiceInfoResponseModel
}> = ({ record }) => {

    const user = localStorageManagement.getUser();
    const searchApi = useSearchInvoiceApis();

    const onClick = () => {
        if (!user?.mst || !record.sobaomat) return;
        searchApi.getXml(user?.mst, record.sobaomat).then(response => {
            if (!response) return;
            const a = document.createElement('a');
            a.href = response;
            a.download = `${user.mst}_${record.sobaomat}.zip`
            a.click();
        });
    }

    return <Button onClick={onClick} icon={<FileProtectOutlined />}>Tải XML</Button>
}
export default SearchByAccountDownloadXmlButton